import logo from './logo.svg';
import './App.css';
import { Cape } from '@capeprivacy/cape-sdk';
import { useState} from "react";
import { useEffect} from "react";
import loglevel from "loglevel";

loglevel.setLevel("trace");

const functionID = "GvxiDwTNH2z8XhPmD5fWep"
const functionToken = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3NjgwMDcwOTEsImlhdCI6MTY2ODAwNzA5MSwiaXNzIjoiZ2l0aHVifDg2NjA4ODQ4Iiwic2NvcGUiOiJmdW5jdGlvbjppbnZva2UiLCJzdWIiOiJHdnhpRHdUTkgyejhYaFBtRDVmV2VwIn0.Wu9CfUB9xX921jm-F5lzXxjY_MUZPXYN2dk97odm-PEev95IBn5ji5QgjkWRjSvWygyPI4NbHDqGaTpybv8mKnzgGkCoM6-eG9j7oLM-Pccd4Xl8MvC18PO9BctBFVlR50VFS7dDN40lNB05mUiQyMd1kSr9sYEYHaQqbmIJHo8XyHxrBD7_ra4QNcVPdJDOYlEfiODVyre4osIkvnx1LzqJ62tV2g-HBmRjPiLRV4c-WZpxMGrqMSOxLn_EUBS65mWG9wtJr0PQbisiDzkbChA1rHeS-ogJuQfxs1WS9OPBuuY_y_uzvKPty32I1pTFN3lXjEqzE7ipsqi-n-vYCA"

function App() {
  const [text, setText] = useState("Move around and press Seek to search for the treasure");
  const [status, setStatus] = useState(false);
  const [lat, setlat] = useState(0.0)
  const [long, setlong] = useState(0.0)
  const [targetLocation, setTargetLocation] = useState('')


  async function encrypt() {
    const client = new Cape({
      functionToken
    });
    try {
      // encrypt current location and return encrypted object
      var res = await client.encrypt({data: JSON.stringify({
        latitude: lat,
        longitude: long
      })})
      console.log("encrypted location: " + res)
      setText("Your encrypted output: " + res)
    } catch(err) {
      console.log(err)
      setText("Error encrypting location " + err.message)
    }
  }

  function start() {
    console.log("starting")
    setStatus("Welcome to hide and seek!")
  }

  async function connect() {
    const client = new Cape({
        functionToken
      });
    console.log("Connecting...")
    setStatus("Connecting...")

    try {
      await client.connect({id: functionID})
      console.log("Connected")
      setStatus("Connected")
    } catch(err) {
      console.log(err)
      setText("Error connecting: " + err.message)
    }
    return client
  }

  async function seek() {
    const client = await connect()
    console.log("Seeking...")
    setText("Seeking...")
    if (targetLocation === "") {
      setText("Please specify destination")
      return
    }

    try {
      // set the first id as a result of cape encrypt.
      var res = await client.invoke({data: targetLocation})
      res = await client.invoke({data: JSON.stringify({
        latitude: lat,
        longitude: long
      })})
      console.log(res)
      setText(res)
    } catch(err) {
      console.log(err)
      setText("Error invoking function: " + err.message)
      client.disconnect()
    }
    client.disconnect()
  }

  useEffect(() => {
    navigator.geolocation.watchPosition((location) => {
      setlat(location.coords.latitude);
      setlong(location.coords.longitude);
    })
  }, [])

  return (
    <div className="App" onLoad={start}>
      <header className="App-header">
        <p>
          Hide and Seek ({status})
        </p>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Your location {lat}, {long}
        </p>
        <button onClick={encrypt}>Hide</button>
        <p>
          <div class="request-top" style={{whiteSpace: 'pre-wrap', overflowWrap: 'anywhere', percentage: 80}}>
            <p>{text}</p>
          </div>
        </p>
        <p>
          <input className="e-input"
            type="text" 
            placeholder="Enter destination" 
            onChange={e => setTargetLocation(e.target.value)} 
            value={targetLocation}/>
        </p>
        <button onClick={seek}>Seek</button>
      </header>
    </div>
  );
}

export default App;
